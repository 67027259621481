/* base is the main bg of the whole website */
#base {
    position: relative;
    width: 100%;
    height: 100vh;
    /* padding: 50px 75px; */
    background-color: rgb(251, 251, 251);
    overflow-x: hidden;
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 75px;
    z-index: 99999;
    background-color: #fff;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.013);
}

#navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

#navbar li {
    list-style: none;
    padding: 0 15px;
    position: relative;
}

#navbar li a {
    text-decoration: none;
    color: #323232;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    transition: 0.3s ease-in-out;
}

/* #navbar li a:hover {
    color: #CE76A6
} */

/* create one line beneath hover effect */
/* #navbar li a:hover::after {
    content: "";
    width: 30%;
    height: 2px;
    background-color: #CE76A6;
    position: absolute;
    bottom: -4px;
    left: 20px;

} */

#mobile {
    display: none;
}

#mobile i {
    align-items: center;
}

#landing-base {
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 80px 75px;
}

#left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    height: 100%;
}

#right {
    width: 50%;
    height: 100%;
}

#img-for-tabsnphones {
    display: none;
}

#landing-base #left h1 {
    font-size: 60px;
    font-weight: 800;
    color: #7F6CE6;
    margin-bottom: 2rem;
}

#landing-base #left p {
    font-size: 20px;
    font-weight: 500;
    color: #323232;
    margin-bottom: 2rem;
}

#landing-base #left button {
    padding: 10px 25px;
    background-color: #CE76A6;
    box-shadow: 0px 6px 12px #ce76a65c;
    color: #fff;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-weight: 800;
}

#landing-base #right #pic {
    position: absolute;
    top: 10%;
    right: -8%;
    width: 600px;
}

/* design elements positioning  */
#design1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 15rem;
}

#achivmnts {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 6rem 0px;
    background-color: #EEDCED;
    margin-top: 3rem;
}

#achivmnts #textprt {
    width: 40%;
    height: 100%;
    /* background-color: #fff; */
    padding: 6rem 3rem;
}

#achivmnts #textprt h1 {
    font-size: 50px;
    font-weight: 500;
    color: #7F6CE6;
    margin-bottom: 2rem;
}

#achivmnts #textprt button {
    padding: 10px 25px;
    background-color: #CE76A6;
    box-shadow: 0px 6px 12px #ce76a65c;
    color: #fff;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-weight: 800;
}

#achivmnts #imgprt #mobile {
    display: none;
}

#achivmnts #imgprt {
    width: 60%;
    height: 100%;
    /* background-color: rgb(106, 59, 59); */
    text-align: center;
}

#achivmnts #imgprt img {
    width: 38rem;
}

/* section of courses */
#courses {
    position: relative;
    width: 100%;
    padding: 8rem 0px;
    text-align: center;
}

#courses h1 {
    font-size: 50px;
    font-weight: 500;
    color: #7F6CE6;
    margin-bottom: 2rem;
}

#courses #design2 {
    position: absolute;
    top: 15%;
    left: 0;
    width: 30rem;
}

#courses #design3 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20rem;
}

/*section of colleges*/
#colleges {
    width: 100%;
    text-align: center;
    padding: 0rem 0rem 2rem 0rem;
    /*change this later*/
}
#colleges #underlay {
    position: relative;
    width: 100%;
    height: 30rem;
    background-image: url(../../assets/images/pexels-idriss-meliani-2982449.jpg);
    background-position: center;
    background-size: cover;
    margin-bottom: 2rem;
}

#colleges #underlay #overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.43);
    color: #fff;
    text-align: center;
}

#colleges #underlay #overlay h1 {
    font-size: 50px;
    font-weight: 500;
}

#colleges #underlay #overlay p {
    font-size: 20px;
    margin: 1rem 0rem;
}

#colleges #underlay #overlay button {
    display: flex;
    align-items: center;
    padding: 10px 25px;
    background-color: #CE76A6;
    color: #fff;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-weight: 500;
}

#colleges #underlay #overlay button a {
    display: flex;
    align-items: center;
}

#colleges #underlay #overlay button i {
    font-size: 18px;
}

#about {
    width: 100%;
    text-align: center;
    /* padding: 8rem 0rem 8rem 0rem; */

    /*change this later*/
}

#about #underlay {
    position: relative;
    width: 100%;
    height: 30rem;
    background-image: url(../../assets/images/about.jpg);
    background-position: center;
    background-size: cover;
    /* margin-bottom: 2rem; */
}

#about #underlay #overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.43);
    color: #fff;
    text-align: center;
}

#about #underlay #overlay h1 {
    font-size: 50px;
    font-weight: 500;
}

#about #underlay #overlay p {
    font-size: 20px;
    margin: 1rem 0rem;
    padding: 2rem 2rem;
}

#testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    background-color: #EEDCED;
    padding: 5rem 0rem;
}

#testimonials #design4 {
    position: absolute;
    top: 15rem;
    left: 0;
}

#testimonials h1 {
    font-size: 38px;
    font-weight: 500;
    color: #323232;
    text-align: center;
    margin-bottom: 5rem;
    padding: 0rem 1rem;
}

#testimonials #review-card {
    width: 50rem;
    height: 30rem;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.53);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(1.5px);
    border: 2px solid rgba(255, 255, 255, 0.799);
    text-align: center;
}

footer {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #7F6CE6; */
    padding: 40px 75px;
}

footer .cols {
    width: 33.3%;
    height: 100%;
    /* background-color: rgb(25, 25, 25); */
    margin-right: 3rem;
}

footer .cols:nth-last-child(1) {
    margin-right: 0
}

footer #futerlogo {
    width: 5rem;
    margin-bottom: 1rem;
}

footer #quote {
    font-size: 16px;
    font-style: italic;
    color: #3232327d;
    margin-bottom: 6rem;
}

/* to style all footer headings */
footer .custom-footer-styling {
    font-size: 18px;
    text-transform: uppercase;
    color: #7F6CE6
}

footer .logos {
    margin-top: .7rem;
    font-size: 20px;
}

footer .logos a {
    text-decoration: none;
    color: #323232;
    margin-right: 1rem;
}

footer .logos a:hover {
    color: #000
}

footer #follow-div {
    width: 33%;
    position: relative;
}

footer #follow-bird {
    position: absolute;
    bottom: 50%;
    right: 0
}

footer #quicklinks-div {
    width: 33%;
    position: relative;
}

footer #contact-us {
    width: 33%;
    position: relative;
}

footer #quicklinks-bird {
    position: absolute;
    bottom: 50%;
    right: -6%
}

footer #contact-bird {
    position: absolute;
    bottom: 50%;
    right: -11%
}

footer #quicklinks {
    margin-bottom: 2rem;
}

footer #contact {
    margin-bottom: 2rem;
}

footer #footer-links {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* background: #000; */
}

footer #footer-links a {
    text-decoration: none;
    color: #323232;
}

footer #footer-links a:hover {
    color: #000
}

footer #contact-links {
    height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* background: #000; */
}

footer #contact-links a {
    text-decoration: none;
    color: #323232;
}

footer #contact-links a:hover {
    color: #000
}

/* for mobile screens */
@media only screen and (max-width:768px) {
    nav {
        position: fixed;
        top: 0rem;
        left: 0rem;
        padding: 35px 45px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.085);
        background-color: white;
        z-index: 99999;
    }

    #navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 4.4rem;
        right: -30rem;
        width: 300px;
        height: 100vh;
        background-color: #CE76A6;
        box-shadow: 0 40px 60px #ce76a661;
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
    }

    #navbar.active {
        right: 0rem;
    }

    #navbar li {
        margin-bottom: 25px;
    }

    #navbar li a {
        color: #fff;
    }

    #navbar li a:hover {
        color: #fff
    }

    #mobile {
        display: block;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
        color: #CE76A6;
    }

    #landing-base {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 7rem;
        padding-left: 0;
        padding-right: 0;
    }

    #left {
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
        width: 100%;
        height: 100%;
        padding: 0;
        text-align: center;
    }

    #right {
        display: none;
    }

    #landing-base #left h1 {
        font-size: 40px;
        padding: 0rem 2rem;
    }

    #landing-base #left p {
        font-size: 20px;
        padding: 0rem 2rem;
    }

    #img-for-tabsnphones {
        display: flex;
        margin-bottom: 3rem;
    }

    #img-for-tabsnphones #pic-for-tabsnphones {
        max-width: 20rem;
    }

    #landing-base #right #pic {
        position: initial;
        width: 300px;
        margin-top: 8rem;
    }

    #design1 {
        position: absolute;
        top: 4rem;
        left: -1rem;
        width: 10rem;
    }

    #achivmnts {
        display: block;
        height: 40rem;
        padding: .2rem 0rem;
        background-color: #EEDCED;
        margin-top: 3rem;
        margin-bottom: 3rem
    }

    #achivmnts #textprt {
        width: 100%;
        height: 25%;
        padding: 3rem 2rem;
        margin-bottom: 5rem;
    }

    #achivmnts #textprt h1 {
        font-size: 30px;
        text-align: center;
    }

    #achivmnts #textprt button {
        display: none;
    }

    #achivmnts #imgprt #mobile {
        display: block;
        padding: .7rem 3rem;
        background-color: #CE76A6;
        box-shadow: 0px 6px 12px #ce76a65c;
        color: #fff;
        border: none;
        border-radius: .5rem;
        text-transform: uppercase;
        font-weight: 800;
    }

    #achivmnts #imgprt {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50%;
        /* background-color: rgb(106, 59, 59); */
        text-align: center;
    }

    #achivmnts #imgprt img {
        width: 20rem;
        margin-bottom: 5rem;
    }

    #courses {
        padding: 0px 0px 3rem 0px;
    }

    #courses h1 {
        font-size: 30px;
        font-weight: 500;
        color: #7F6CE6;
        margin-bottom: 2rem;
        text-align: center;
    }

    #courses #design2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 20rem;
    }

    #courses #design3 {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 10rem;
    }

    #colleges #underlay #overlay h1 {
        font-size: 40px;
    }

    #colleges #underlay #overlay p {
        font-size: 18px;
        padding: 2rem 1rem;
    }

    #about {
        width: 100%;
        height: 800px;
        text-align: center;
        background-color: rgb(255, 140, 140);
        /* padding: 8rem 0rem 8rem 0rem; */

        /*change this later*/
    }

    #about #underlay {
        position: relative;
        width: 100%;
        height: 50rem;
        background-image: url(../../assets/images/about.jpg);
        background-position: center;
        background-size: cover;
        /* margin-bottom: 2rem; */
    }

    #about #underlay #overlay h1 {
        font-size: 40px;
    }

    #about #underlay #overlay p {
        font-size: 18px;
        padding: 2rem 2rem;
    }

    #testimonials #design4 {
        display: none;
    }

    #testimonials #review-card {
        width: 20rem;
        height: 40rem;
        /* From https://css.glass */
        background: rgba(255, 255, 255, 0.53);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(1.5px);
        -webkit-backdrop-filter: blur(1.5px);
        border: 2px solid rgba(255, 255, 255, 0.799);
        text-align: center;
    }

    footer {
        height: 80%;
        display: block;
        padding: 20px 45px;
        /* background-color: rgb(184, 107, 107); */
    }

    footer .cols {
        width: 100%;
        height: 170px;

    }

    footer #follow-div {
        width: 130px;
        position: relative;
    }

    footer #follow-bird {
        position: absolute;
        bottom: 5px;
        right: 0
    }

    footer #quicklinks-div {
        display: none
    }

    footer #footer-links {
        display: none
    }

    footer #contact-us {
        width: 130px;
        position: relative;
        margin-bottom: -1rem;
    }
}

/* for tablets */
@media only screen and (min-width : 768px) and (max-width : 1024px) {

    nav {
        position: fixed;
        top: 0rem;
        left: 0rem;
        padding: 35px 45px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.085);
        background-color: rgb(255, 255, 255);
        z-index: 99999;
    }

    #navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 4.4rem;
        right: -30rem;
        width: 300px;
        height: 100vh;
        background-color: #CE76A6;
        box-shadow: 0 40px 60px #ce76a661;
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
    }

    #navbar.active {
        right: 0rem;
    }

    #navbar li {
        margin-bottom: 25px;
    }

    #navbar li a {
        color: #fff;
    }

    #navbar li a:hover {
        color: #fff
    }

    #mobile {
        display: block;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
        color: #CE76A6;
    }

    #landing-base {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 7rem;
        padding-left: 0;
        padding-right: 0;
    }

    #left {
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
        width: 100%;
        height: 100%;
        padding: 0;
        text-align: center;
    }

    #right {
        display: none;
    }

    #img-for-tabsnphones {
        display: flex;
        margin-bottom: 4rem;
    }

    #img-for-tabsnphones #pic-for-tabsnphones {
        max-width: 50rem;
    }

    #landing-base #right #pic {
        position: initial;
        width: 300px;
        margin-top: 8rem;
    }

    #design1 {
        position: absolute;
        top: 4rem;
        left: -1rem;
        width: 10rem;
    }

    #achivmnts {
        display: block;
        height: 50rem;
        padding: .2rem 0rem;
        background-color: #EEDCED;
        margin-top: 3rem;
        margin-bottom: 3rem
    }

    #achivmnts #textprt {
        width: 100%;
        height: 45%;
        padding: 3rem 2rem;
        margin: 0
    }

    #achivmnts #textprt button {
        display: none;
    }

    #achivmnts #imgprt #mobile {
        display: block;
        padding: .7rem 3rem;
        background-color: #CE76A6;
        box-shadow: 0px 6px 12px #ce76a65c;
        color: #fff;
        border: none;
        border-radius: .5rem;
        text-transform: uppercase;
        font-weight: 800;
    }

    #achivmnts #imgprt {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50%;
        /* background-color: rgb(106, 59, 59); */
        text-align: center;
    }

    #achivmnts #imgprt img {
        width: 30rem;
        margin-bottom: 5rem;
    }

    #courses {
        padding: 3rem 0px;
    }

    #courses h1 {
        font-size: 30px;
        font-weight: 500;
        color: #7F6CE6;
        margin-bottom: 2rem;
        text-align: center;
    }

    #courses #design2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 20rem;
    }

    #courses #design3 {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 10rem;
    }

    #colleges #underlay #overlay h1 {
        font-size: 40px;
    }

    #colleges #underlay #overlay p {
        font-size: 18px;
        padding: 2rem 1rem;
    }

    #about {
        width: 100%;
        height: 800px;
        text-align: center;
        /* padding: 8rem 0rem 8rem 0rem; */

        /*change this later*/
    }

    #about #underlay {
        position: relative;
        width: 100%;
        height: 50rem;
        background-image: url(../../assets/images/about.jpg);
        background-position: center;
        background-size: cover;
        /* margin-bottom: 2rem; */
    }

    #about #underlay #overlay h1 {
        font-size: 40px;
    }

    #about #underlay #overlay p {
        font-size: 18px;
        padding: 2rem 2rem;
    }

    #testimonials {
        margin-top: 0rem;
    }

    #testimonials #design4 {
        display: none;
    }

    #testimonials #review-card {
        width: 20rem;
        height: 40rem;
        /* From https://css.glass */
        background: rgba(255, 255, 255, 0.53);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(1.5px);
        -webkit-backdrop-filter: blur(1.5px);
        border: 2px solid rgba(255, 255, 255, 0.799);
        text-align: center;
    }

    footer {
        height: 80%;
        display: block;
        padding: 20px 45px;
    }

    footer .cols {
        width: 100%;
        height: 170px;
    }

    footer #follow-div {
        width: 130px;
        position: relative;
    }

    footer #follow-bird {
        position: absolute;
        bottom: 5px;
        right: 0
    }

    footer #quicklinks-div {
        display: none
    }

    footer #footer-links {
        display: none
    }

    footer #contact-us {
        width: 130px;
        position: relative;
    }

    footer #contact-bird {
        position: absolute;
        bottom: 7px;
        right: -11px;
    }
}