/* .slick-slider .slick-dots{
    margin-top: 15rem !important;
} */
#colg-base {
    min-width: 15rem;
    max-width: 15rem;
    /* react me min width hi set krte h agar nhi chahte ki card ki size khudse squeeze hojaye  */
    height: 15rem;
    padding: 2rem;
    background-color: #E8E6F1;
    border-radius: 55px;
}
#colg-base #img-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 55px 55px 0px 0px;
    margin-bottom: 1rem;
}
#colg-base #img-div img{
    width: 50%;
    border-radius: 55px 55px 0px 0px;
}
#colg-base #content-div {
    width: 100%;
    height: 13rem;
    padding: 15px 0px;
    text-align: center;
    color: #323232;
    display: flex;
    flex-direction: column;
}

#colg-base #content-div h4 {
    font-size: 18px;
    font-weight: 500;
    color:#7F6CE6;
}

.slick-prev {
    left: 3% !important;
    z-index: 1;
  }
  .slick-next {
    right: 3% !important;
    z-index: 1;
  }

  .slick-prev:before,
.slick-next:before {
  color: #7F6CE6;
}