#card-base {
    min-width: 17rem;
    max-width: 17rem;
    /* react me min width hi set krte h agar nhi chahte ki card ki size khudse squeeze hojaye  */
    height: 25rem;
    background-color: rgb(237, 237, 237);
    border-radius: 55px;
}
#card-base #img-div {
    width: 100%;
    height: 10rem;
    /* background-color: rgb(255, 98, 98); */
    border-radius: 55px 55px 0px 0px;
}
#card-base #img-div img{
    width: 100%;
    height: 100%;
    border-radius: 55px 55px 0px 0px;
}
#card-base #content-div {
    width: 100%;
    height: 13rem;
    padding: 15px 30px;
    text-align: center;
    color: #323232;
    display: flex;
    flex-direction: column;
}

#card-base #content-div h4 {
    font-weight: 500;
}

#card-base #content-div p {
    font-size: 12px;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 15px;
}

#card-base #content-div button {
    padding: 10px 18px;
    background-color: #CE76A6;
    box-shadow: 0px 6px 12px #ce76a646;
    color: #fff;
    border: none;
    border-radius: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    align-self: center;
    margin-top:auto;
}