/* secondpage base is the main bg of the whole website */
#secondpage-base{
    width: 100%;
    height: 100vh;
    padding: 3rem 0px 0px 0px;
    background-color: #f1f1f1;
    overflow-x: hidden;
}
#upcoming-evnts-illus{
    position: relative;
    width: 100%;
    height: 30rem; 
    /*height can be changes to rem*/
    background-image: url(../../assets/images/humans2.png);
    background-position: center;
    background-size: cover;
}
#upcoming-evnts-content{
    width: 800px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 9999;
    /* background-color: #fff; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#upcoming-evnts-content h1{
    color: #323232;
    font-size: 65px;
}
#upcoming-evnts-content h4{
    color: rgba(50, 50, 50, 0.3);
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.53em;
    font-weight: 900;
}
#upcoming-evnts-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20rem;
}
#upcoming-evnts-card h2{
    text-align: center;
    font-weight: 500;
    color: rgba(50, 50, 50, 0.72);
    margin-bottom: 3rem;
}
#upcoming-evnts-card button{
    padding: 10px 25px;
    background-color: #CE76A6;
    box-shadow: 0px 6px 12px #ce76a65c;
    color: #fff;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    font-weight: 800;
}

@media only screen and (max-width:768px){
    #upcoming-evnts-illus{
        position: relative;
        width: 100%;
        height: 100vh; 
    padding: 4.5rem 0px 0px 0px;
        /*height can be changes to rem*/
        background-image: url(../../assets/images/humansmob.png);
        background-position: center;
    }
    #upcoming-evnts-content h1{
        color: #323232;
        font-size: 34px;
    }
    #upcoming-evnts-content h4{
        color: rgba(50, 50, 50, 0.3);
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.53em;
        font-weight: 900;
    }
    #upcoming-evnts-card{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 20rem;
        padding:2rem;
    }
    #upcoming-evnts-card h2{
        font-size: 20px;
    }
}
